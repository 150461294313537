
import { defineComponent, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    let validateForm = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
    });

    let verifying = reactive({ status: true });
    let resendVerificationEmail = reactive({ status: false });
    let resendClicked = reactive({ status: false });

    //Form submit function
    const submitEmailVerification = () => {
      let payload = {
        id: route.params.id,
        hash: route.params.hash,
        sig: route.query.signature,
        exp: route.query.expires,
      };

      // dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.VERIFY_EMAIL, payload)
          .then(() => {
            const resp = store.getters.getResponses;

            if (resp.code == 200) {
              Swal.fire({
                text: "Your email is verified! An admin will activate your account soon",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Go to Login",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                router.push({ name: "sign-in" });
              });
            } else if (resp.code == 423) {
              Swal.fire({
                text: "Your account has already been activated",
                icon: "warning",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                router.push({ name: "sign-in" });
              });
            } else {
              Swal.fire({
                text: "Unable to verify your email",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                resendVerificationEmail.status = true;
                verifying.status = false;
              });
            }
          })
          .catch(() => {
            let errText = "";
            const err = store.getters.getErrors;
            if (err.code == 401) {
              errText = store.getters.getErrors.error;
            }

            Swal.fire({
              text: errText,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }, 2000);
    };

    //Form submit function
    const onSubmitResendEmailVerification = (values) => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      // dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.RESEND_EMAIL_VERIFICATION, values)
          .then(() => {
            const resp = store.getters.getResponses;

            Swal.fire({
              text: resp.message,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              // Go to page after successfully login
              router.push({ name: "sign-in" });
            });
          })
          .catch(() => {
            // Alert then login failed
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    return {
      submitEmailVerification,
      onSubmitResendEmailVerification,
      validateForm,
      submitButton,
      resendClicked,
      verifying,
      resendVerificationEmail,
    };
  },

  mounted() {
    this.$nextTick(function () {
      this.submitEmailVerification();
    });
  },
});
